import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthStore } from "../../store";
import { useConsumeInvite } from "@app/shared/hooks/post/consume-invite";
import { getOrganizations } from "@app/shared/hooks/get/organizations";

const Invite = () => {
  const [token, updateAuthUser, setInviteToken] = useAuthStore((state) => [
    state.token,
    // state.inviteToken,
    state.updateAuthUser,
    state.setInviteToken
  ]);

  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("token");

  const consumeInviteMutation = useConsumeInvite(async (data) => {
    const orgs = await getOrganizations();

    updateAuthUser({
      selectedOrg: orgs.find((o) => o.id === data.orgId)
    });

    toast.success("Successfully joined organization: " + data.orgName);
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (token && inviteToken) {
      consumeInviteMutation.mutate(inviteToken, {
        onSettled: () => {
          navigate("/home");
          setInviteToken("");
        }
      });
    }
  }, [inviteToken]);

  return consumeInviteMutation.status === "idle" ||
    consumeInviteMutation.isLoading ? (
    <>Please Wait, Redirecting...</>
  ) : (
    <div className="p-10">
      Could not consume the token.{" "}
      <Link to="/home" className="px-4 py-2 rounded-md bg-primary text-white">
        Go to home
      </Link>
    </div>
  );
};

export default Invite;
