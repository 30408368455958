export function formatNumber(
  num: number,
  options: Intl.NumberFormatOptions = null
) {
  return Intl.NumberFormat(
    "en-US",
    options ?? { maximumFractionDigits: 2, notation: "compact" }
  )
    .format(num)
    .toString();
}
