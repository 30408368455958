import { useEffect, useRef, useState } from "react";
import { User } from "oidc-client-ts";
import { Navigate } from "react-router-dom";
import * as Setting from "./zitadel-setting.helper";
import { useAuthStore } from "@/store";
import dateService from "@/services/date.service";
import { IOrganization } from "@/interfaces";
import { loggerService } from "@/services";

export const AuthCallback = () => {
  const [authUser, setAuthState] = useAuthStore((state) => [
    state.user,
    state.setAuthState
  ]);
  const zitadel = Setting.ZitadelSDK;
  const [authenticated, setAuthenticated] = useState(null);

  const next = useRef("/home");

  useEffect(() => {
    zitadel.userManager
      .signinRedirectCallback()
      .then((user: User) => {
        if (user) {
          setAuthState({
            token: user.access_token,
            expiry: dateService
              .convertUnixToMoment(user.expires_at)
              .toISOString(),
            refreshToken: user.refresh_token,
            user: {
              ...authUser,
              ...user.profile,
              selectedOrg: {} as IOrganization
            }
          });

          if (user.url_state) {
            next.current = decodeURI(user.url_state);
          }

          setAuthenticated(true);
        } else {
          setAuthState();
          setAuthenticated(false);
        }
      })
      .catch((error: any) => {
        setAuthState();
        loggerService.error(error);
        setAuthenticated(false);
      });
  }, []);

  if (authenticated === null) {
    return <div>Loading...</div>;
  }

  if (authenticated && authUser?.["client_id"]) {
    return <Navigate to={next.current} />;
  } else {
    return <div>Failed to authenticated</div>;
  }
};
