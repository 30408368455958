import { LatLngExpression } from 'leaflet';
import { Circle, Popup } from 'react-leaflet'
import { ICluster } from '../../../../interfaces';

const LocationCluster: React.FC<ICluster> = ({cluster_id, device_count, location}) => {

    const position = {
        lat: location.lat,
        lng: location.lgt
    } as LatLngExpression;

    // const fillBlueOptions = { fillColor: 'red', color: 'red' }

    return (
        <Circle radius={(device_count || 1 ) * 250} center={position} key={cluster_id} /* pathOptions={fillBlueOptions} */>
            <Popup>Device Count : {device_count}</Popup>
        </Circle>
    )
}

export default LocationCluster;