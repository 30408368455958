import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";

interface IOverlayLoaderState {
  loader: boolean;
  setLoader: (x: boolean) => void;
}

const useOverlayLoaderStore = create<IOverlayLoaderState>()(
  persist(
    devtools(
      (set) => ({
        loader: false,
        setLoader: (boolVal) =>
          set(
            produce((state) => {
              state.loader = boolVal;
            })
          )
      }),
      { name: "overlay-loader", serialize: { options: true } }
    ),
    {
      name: "overlay-loader", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useOverlayLoaderStore;
