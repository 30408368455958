import { useEffect, useRef } from "react";
import { useConfirmDelete } from "../hooks/use-confirm-delete.hooks";
import Modal from "./modal.component";
import { Button } from "@tremor/react";

const ConfirmDeleteModal = () => {
  const deleteButtonRef = useRef(null);
  const {
    handleDeleteConfirm,
    closeConfirmModal,
    isOpen,
    message,
    setIsOpen,
    isLoading
  } = useConfirmDelete();

  useEffect(() => {
    // focus delete button
    if (isOpen && deleteButtonRef.current) {
      deleteButtonRef.current.focus();
    }
  }, [isOpen]);

  const handleDelete = () => {
    handleDeleteConfirm();
  };

  return (
    <Modal open={isOpen} setOpen={setIsOpen}>
      <div className="flex flex-col gap-4 p-6 max-w-md bg-background text-contentColor">
        <h2 className="text-xl font-semibold mb-2">Confirm Delete</h2>
        <p className="mb-4">{message}</p>
        <div className="flex gap-2 justify-end">
          <Button variant="secondary" onClick={closeConfirmModal}>
            Cancel
          </Button>
          <Button
            ref={deleteButtonRef}
            loading={isLoading}
            onClick={handleDelete}
            color="red"
            className="text-white"
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
