const storageTypes = {
  local: localStorage,
  session: sessionStorage
};

export enum UserRole {
  Admin = "OWNER",
  Member = "MEMBER"
}

export enum AuthorizationResult {
  Success = 1,
  LoginRequired = 2,
  Unauthorized = 3
}

export enum RoutePermissionType {
  OneRoleRequired = 1,
  AllRolesRequired = 2,
  OnlyLoginRequired = 3
}

export enum EventType {
  OneOnOneEvent = "ONE_ON_ONE_EVENT"
}

const keys = {
  auth: "authInfo",
  storage: storageTypes.session
};

class AppUrls {
  baseUrl = `${process.env.REACT_APP_API_URL}`;
  googleSheetsBaseUrl = `${process.env.REACT_APP_GOOGLE_SHEET_BASE_URL}`;
  logError = `error/log-error`;
}

const appConstants = {
  keys,
  urls: new AppUrls()
};

export default appConstants;
