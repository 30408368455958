import { useQuery } from "react-query";

import {
  IOrganization,
  IOrganizationResponse
} from "@interfaces/shared.interface";
import networkService from "@services/network.service";

export const getOrganizations = async () => {
  const { data, ok } = await networkService.get<IOrganizationResponse>(
    `organizations`
  );

  if (ok) {
    return data;
  } else return null;
};

export const useGetOrganizations = (
  successCb: ((data: IOrganization[]) => void) | null = null
) => {
  const res = useQuery(["getOrganizations"], async () => {
    const projects = await getOrganizations();

    successCb && successCb(projects);

    return projects;
  });

  return res;
};
