import { useQuery } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { IFleet, IFleetsResponse } from "@interfaces/fad.interface";

export const getFleets = async (projectId: string, orgId: string, params) => {
  const { data, ok } = await networkService.get<IFleetsResponse>(
    `projects/${projectId}/fleets/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.fleets;
  } else return null;
};

export const useGetFleets = (
  params: any = {},
  successCb: ((data: IFleet[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getFleets", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const fleets = await getFleets(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(fleets);

      return fleets;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
