import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export interface IUpdateDashboardPayload {
  meta_data: { layouts: any };
}

interface IUpdateDashboardResponse {
  ok: number;
}

export const updateDashboard = async (
  projectId: string,
  orgId: string,
  dashboardId: string,
  payload: any
) => {
  const { ok } = await networkService.patch<IUpdateDashboardResponse>(
    `projects/${projectId}/dashboards/${dashboardId}`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateDashboard = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async ({
      data,
      dashboardId
    }: {
      data: IUpdateDashboardPayload;
      dashboardId: string;
    }) =>
      await updateDashboard(
        selectedProject.id,
        user.selectedOrg.id,
        dashboardId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDashboards");
      }
    }
  );

  return res;
};
