import { useMutation } from "react-query";

import networkService from "@services/network.service";

interface IInviteData {
  orgId: string;
  orgName: string;
}

interface IInviteConsumeResponse {
  data: IInviteData;
  ok: number;
}

export const consumeInvite = async (inviteToken: string) => {
  const { data, ok } = await networkService.post<IInviteConsumeResponse>(
    `invite?token=${inviteToken}`,
    {}
  );

  if (ok) {
    return data;
  } else return null;
};

export const useConsumeInvite = (onSuccess) => {
  const res = useMutation(
    async (token: string) => await consumeInvite(token),
    {
      onSuccess
    }
  );

  return res;
};
