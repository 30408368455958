import dateService from "@services/date.service";

export const generateGraphData = (
  data: {},
  sources: string[],
  dataObj: {}
) => {
  let isData = false;
  let processedData;

  let yMIN = 0;
  let yMAX = 0;

  if (Object.keys(data).length) {
    let max = data[sources[0]]?.length;
    let maxLine = sources[0];

    for (let i = 1; i < sources.length; i++) {
      if (max < data[sources[i]]?.length) {
        max = data[sources[i]]?.length;
        maxLine = sources[i];
      }
    }

    if (max > 0) {
      isData = true;
      processedData = new Array(max);
      for (let i = 0; i < processedData.length; i++) {
        let obj = Object.create(dataObj);
        sources.forEach((source) => {
          const logs = data[source][i];
          obj[source] = logs?.value || NaN;

          if (logs?.value) {
            if (yMIN > logs.value) yMIN = logs.value;
            if (yMAX < logs.value) yMAX = logs.value;
          }

          if (source === maxLine) {
            obj.value = dateService.convertToUTC2(logs.time).format("DD/MM");
          }
        });
        processedData[i] = { ...obj };
      }
    }
  }

  yMAX += yMAX * 0.1;
  yMAX = Math.ceil(yMAX);

  return { isData, processedData, yMIN, yMAX };
};

export const getInfographicSum = (
  data: Array<{ value: number; time: string }>,
  filter: string
) => {
  let sum = 0;
  data.forEach((d) => (sum += d.value));
  sum = sum * (1 / 3600);

  if (filter) {
    sum = sum * (1 / 1000);
  }
  return sum;
};
