import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useAuthStore } from "../../store";
import * as Setting from "./zitadel-setting.helper";
import { toast } from "react-toastify";
import useConfigStore from "@/store/config.store";

const Jump = () => {
  const [token, expiry, setAuthState] = useAuthStore((state) => [
    state.token,
    state.expiry,
    state.setAuthState
  ]);

  const [currentVersion, setCurrentVersion] = useConfigStore((state) => [
    state.version,
    state.setVersion
  ]);

  const [versionMatched, setVersionMatched] = useState(null);

  useEffect(() => {
    // Do a version check
    const latestVersion = process.env.REACT_APP_VERSION;

    if (currentVersion === null) {
      setCurrentVersion(latestVersion);
      setVersionMatched(true);
    } else {
      if (currentVersion !== latestVersion) {
        setVersionMatched(false);
        localStorage.clear();
        sessionStorage.clear();
        setAuthState();
      } else {
        setVersionMatched(true);
      }
    }
  }, [currentVersion]);

  useEffect(() => {
    // wait for version check
    if (versionMatched === null) {
      return;
    }

    if (!token || !expiry) {
      Setting.ZitadelSDK.authorize();
    } else {
      // check expiry
      const now = new Date().getTime();
      const expiryDate = new Date(expiry).getTime();

      if (now > expiryDate) {
        setAuthState();
        toast.error("Authorization Token expired, redirecting to auth.", {
          pauseOnHover: false,
          autoClose: 3000
        });
        setTimeout(() => {
          Setting.ZitadelSDK.authorize();
        }, 3000);
      }
    }
  }, [expiry, token, versionMatched]);

  return <Outlet />;
};

export default Jump;
