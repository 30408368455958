import { useMutation, useQueryClient } from "react-query";

import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

export const deleteDashboard = async (
  projectId: string,
  orgId: string,
  dashboardId: string
) => {
  const { ok } = await networkService.delete<{ ok: number }>(
    `projects/${projectId}/dashboards/${dashboardId}`,
    {},
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useDeleteDashboard = () => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (dashboardId: string) =>
      await deleteDashboard(
        selectedProject.id,
        user.selectedOrg.id,
        dashboardId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDashboards");
      }
    }
  );

  return res;
};
