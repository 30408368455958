import {
  UserManagerSettings,
  UserManager,
  WebStorageStateStore,
  SigninRedirectArgs,
  SignoutRedirectArgs,
  Log
} from "oidc-client-ts";

Log.setLogger(console);

export type ZitadelConfig = Partial<UserManagerSettings> & {
  project_resource_id?: string;
};

interface ZitadelAuth {
  authorize(args?: SigninRedirectArgs): Promise<void>;
  signout(args?: SignoutRedirectArgs): Promise<void>;
  userManager: UserManager;
}

export function createZitadelAuth(zitadelConfig: ZitadelConfig): ZitadelAuth {
  const authConfig: UserManagerSettings = {
    authority: `${zitadelConfig.authority}`,
    client_id: `${zitadelConfig.client_id}`,
    redirect_uri:
      zitadelConfig.redirect_uri ?? "http://localhost:3000/callback",
    response_type: "code",
    scope:
      zitadelConfig.scope ??
      `openid profile email ${
        zitadelConfig.project_resource_id
          ? `urn:zitadel:iam:org:project:id:${zitadelConfig.project_resource_id}:aud urn:zitadel:iam:org:projects:roles`
          : ""
      }`,
    prompt: zitadelConfig.prompt ?? "",
    post_logout_redirect_uri:
      zitadelConfig.post_logout_redirect_uri ?? "http://localhost:3001/",
    response_mode: "query",
    ...zitadelConfig
  };

  const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    loadUserInfo: true,
    ...authConfig
  });

  const authorize = (args = {}) => {
    const location = window.location;
    let url_state: string;

    if (location.search.length) {
      url_state = encodeURI(location.pathname + location.search);
    } else {
      url_state = encodeURI(location.pathname);
    }

    return userManager.signinRedirect({ ...args, url_state });
  };

  const signout = (args = {}) => {
    return userManager.signoutRedirect(args);
  };

  const oidc: ZitadelAuth = {
    authorize,
    signout,
    userManager
  };

  return oidc;
}
