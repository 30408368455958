import React from "react";
import { IGauge } from "@interfaces/index";
import { getRectMappedY, getTempLineYCoords } from "./gauge-helper";
import { formatNumber } from "@/app/shared/utils/dashboard.utils";

/* 
    Component: Temperature Meter [ gauge 2 ]
    Props:  {
        min : limitimg minimum value [ number ]
        max : limitimg maximum value [ number ]
        minValue : value to plot left(min) part of gauge ragning betwenn min & max [ number ] 
        maxValue : value to plot right(max) part of gauge ragning betwenn min & max[ number ]
        units : unit for values [ string ] { e.g. C for celcius}
        maxScales : bool to give maximized width & height to UI cards 
    }
    Author: Aaditya Kashid
*/

const TempMeter: React.FC<IGauge> = ({
  minValue = 11,
  maxValue = 120,
  min = 0,
  max = 180,
  units = "C",
  showExample = false
}) => {
  const minR = getRectMappedY(min, max, 45, 5, minValue);
  const maxR = getRectMappedY(min, max, 45, 5, maxValue);

  const tempLineCoords = getTempLineYCoords(11, 5, 45, minR, maxR, min, max);

  return (
    <div className="h-full">
      <div className="h-full">
        <svg
          width="100%"
          height={showExample ? "300" : "calc(100% - 40px)"}
          viewBox="0 3 50 50"
        >
          <defs>
            <linearGradient
              id="Temp__backGradient"
              x1="0"
              x2="0"
              y1="0"
              y2="1"
            >
              <stop offset="0%" stopColor="rgba(22, 111, 246, 0.15)" />
              <stop offset="100%" stopColor="rgba(22, 111, 246, 0.7)" />
            </linearGradient>
            <linearGradient id="Temp__minGradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor="rgba(22, 111, 246, 1)" />
              <stop offset="100%" stopColor="rgba(0, 56, 140, 1)" />
            </linearGradient>
            <linearGradient id="Temp__maxGradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor="rgba(240, 5, 0, 1)" />
              <stop offset="100%" stopColor="rgba(215, 215, 215, 0)" />
            </linearGradient>
          </defs>
          <rect
            x={15}
            y={5}
            width={10}
            height={45}
            fill="url(#Temp__backGradient)"
          />
          <rect
            x={15}
            y={minR}
            width={10}
            height={50 - minR}
            fill="url(#Temp__minGradient)"
          />
          <rect
            x={15}
            y={maxR}
            width={10}
            height={50 - maxR}
            fill="url(#Temp__maxGradient)"
          />

          <line
            x1={15}
            y1={minR}
            x2={30}
            y2={minR}
            stroke="#166FF6"
            strokeWidth="0.4"
          />

          <line
            x1={15}
            y1={maxR}
            x2={30}
            y2={maxR}
            stroke="#E21B17"
            strokeWidth="0.4"
          />

          {tempLineCoords.points.map((line, index) => (
            <g key={index}>
              <line
                x1={16}
                y1={line.y}
                x2={24}
                y2={line.y}
                stroke={line.color}
                strokeWidth="0.2"
              />
              <text
                x={10 - max.toString().length}
                y={line.y}
                style={{ fontSize: "2.8", fontWeight: "400" }}
                fontSize="2.8px"
                dominantBaseline="middle"
                textAnchor="middle"
                className="text-contentColor fill-contentColor"
              >
                {formatNumber(tempLineCoords.values[index], {
                  maximumFractionDigits: 1
                })}
              </text>
            </g>
          ))}

          <text
            x={32}
            y={minR}
            style={{ fontSize: "5.5", fontWeight: "400" }}
            fontSize="5.5px"
            dominantBaseline="middle"
            textAnchor="start"
            fill="rgba(19, 102, 229, 1)"
          >
            <tspan>
              {formatNumber(minValue, { maximumFractionDigits: 0 })}
            </tspan>
            <tspan
              style={{ fontSize: "3.5", fontWeight: "400" }}
              fontSize="3.5px"
            >
              &nbsp;{units}
            </tspan>
          </text>
          <text
            x={32}
            y={maxR}
            style={{ fontSize: "3", fontWeight: "500" }}
            fontSize="3px"
            dominantBaseline="middle"
            textAnchor="start"
            fill="rgba(226, 27, 23, 1)"
          >
            <tspan>
              Max {formatNumber(maxValue, { maximumFractionDigits: 0 })}
            </tspan>
            <tspan
              style={{ fontSize: "2.5", fontWeight: "500", marginLeft: "1px" }}
              fontSize="2.5px"
            >
              &nbsp;{units}
            </tspan>
          </text>
        </svg>
      </div>
    </div>
  );
};

export default TempMeter;
