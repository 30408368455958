import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";
import {
  IHeatMapInput,
  initialValues
} from "../../app/dashboard/components/forms/heatmap.helper";

const useCreateHeatmapStore = create<IHeatMapInput>()(
  persist(
    devtools(
      (set) => ({
        inputValues: initialValues,
        setInputValues: (values: IHeatMapInput["inputValues"]) =>
          set(
            produce((state) => {
              state.inputValues = values;
            }),
            false,
            "createHeatmapStore/setInputValues"
          ),
        resetInputValues: () =>
          set(
            produce((state) => {
              state.inputValues = initialValues;
            }),
            false,
            "createHeatmapStore/resetInputValues"
          )
      }),

      { name: "create-heatmap", serialize: { options: true } }
    ),
    {
      name: "create-heatmap", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useCreateHeatmapStore;
