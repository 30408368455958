import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../app/shared/config";

type ThemeType = "dark-default" | "golain" | "none";

interface ISidebarState {
  theme: ThemeType;
  setTheme: (x: string) => void;
}

const useThemeStore = create<ISidebarState>()(
  persist(
    devtools(
      (set) => ({
        theme: "none",
        setTheme: (theme) =>
          set(
            produce((state) => {
              state.theme = theme;
            }),
            false,
            "theme/setTheme"
          )
      }),
      { name: "theme", serialize: { options: true } }
    ),
    {
      name: "theme", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useThemeStore;
