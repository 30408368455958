import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store";
import * as ZitadelSetting from "./zitadel-setting.helper";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const setAuthState = useAuthStore((state) => state.setAuthState);

  React.useEffect(() => {
    ZitadelSetting.ZitadelSDK.signout().then(() => {
      setAuthState();
      sessionStorage.clear();
      localStorage.clear();
    });
  }, [setAuthState, navigate]);

  return <>Logout</>;
};

export default Logout;
