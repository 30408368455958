import { ITag } from "@/interfaces/tags.interface";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";
import { useQuery } from "react-query";

interface ITagsResponse {
  ok: number;
  data: {
    tags: ITag[];
  };
}

export const getTags = async (projectId: string, orgId: string, params) => {
  const { data, ok } = await networkService.get<ITagsResponse>(
    `projects/${projectId}/tags/`,
    params,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  if (ok) {
    return data.tags;
  } else return null;
};

export const useGetTags = (
  params: any = {},
  successCb: ((data: ITag[]) => void) | null = null
) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const res = useQuery(
    ["getTags", selectedProject.id, user.selectedOrg.id, params],
    async () => {
      const taags = await getTags(
        selectedProject.id,
        user.selectedOrg.id,
        params
      );

      successCb && successCb(taags);

      return taags;
    },
    {
      enabled: !!selectedProject.id && !!user.selectedOrg.id
    }
  );

  return res;
};
