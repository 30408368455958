import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../app/shared/config";

interface HeaderButtonState {
  addButtonOverLayOpen: boolean;
  toggleOverLay: (x: boolean) => void;
}

const useHeaderButtonStore = create<HeaderButtonState>()(
  persist(
    devtools(
      (set) => ({
        addButtonOverLayOpen: false,
        toggleOverLay: (open) =>
          set(
            produce((state) => {
              state.addButtonOverLayOpen = open;
            }),
            false,
            "headerbutton/toggleOverLay"
          )
      }),
      { name: "headerbutton", serialize: { options: true } }
    ),
    {
      name: "headerbutton", // unique name
      getStorage: () => appConstants.keys.storage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useHeaderButtonStore;
