import React from "react";
import { Outlet, RouteProps } from "react-router-dom";
import { RoutePermission } from "../../../models";
import { useAuthStore } from "../../../store";
import { useGetOrganizations } from "../hooks/get/organizations";

type TPrivateRouteProps = RouteProps & { routePermission: RoutePermission };

const PrivateRoute: React.FC<TPrivateRouteProps> = ({ routePermission }) => {
  const [token, user] = useAuthStore((state) => [state.token, state.user]);

  // Check for making sure the token is valid
  const { isLoading: orgsLoading, isError: orgsError } = useGetOrganizations(
    (organizations) => {
      // TODO: Set orgs, don't fetch in layout
    }
  );

  if (token && user?.client_id && !orgsLoading && !orgsError) {
    return <Outlet />;
  } else {
    return null;
  }
};

export default PrivateRoute;
