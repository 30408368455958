import { Button } from "@tremor/react";
import errorSvg from "../../../assets/images/error.svg";

const ShowError = () => {
  return (
    <div className="w-full flex-col h-[81vh] flex justify-center items-center">
      <img src={errorSvg} alt="error graphic" />
      <h1 className="font-bold text-2xl mt-6 mb-2">
        Opps! Something went wrong.
      </h1>
      <span className="text-gray-500 text-center text-sm">
        The page you are looking for is either not functioning <br /> anymore
        or currently out of order. Try reloading the page!
      </span>
      <Button onClick={() => window.location.reload()}>Reload Page</Button>
    </div>
  );
};

export default ShowError;
