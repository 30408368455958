const ShowLoading = () => {
  const show = true;

  return (
    <>
      <div
        className={`fixed w-screen ${
          show ? "h-screen" : "h-0"
        } z-50 overflow-hidden flex flex-col items-center justify-center`}
      >
        <div
          className={`top-0 left-0 right-0 bottom-0 w-screen fixed h-screen ${
            show ? "bg-[#10101025]" : "bg-transparent"
          } transition-opacity duration-500 ease-in ${
            show ? "opacity-100" : "opacity-0"
          } backdrop-blur-[1px]`}
        ></div>
        <div
          className={`fixed ${
            show ? "flex" : "hidden"
          } flex items-center justify-center`}
        >
          <div
            className="inline-block h-12 w-12 animate-spin rounded-full text-primary border-8 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowLoading;
