import { useMutation, useQueryClient } from "react-query";
import networkService from "@services/network.service";
import { useAuthStore, useFleetAndDevicesStore } from "@store/index";

// Define the payload type for updating the device shadow
interface IUpdateDeviceShadowPayload {
  shadow: any;
}

export const updateDeviceShadow = async (
  projectId: string,
  orgId: string,
  fleetId: string,
  deviceId: string,
  payload: IUpdateDeviceShadowPayload
) => {
  const { ok } = await networkService.post<{ ok: number }>(
    `projects/${projectId}/fleets/${fleetId}/devices/${deviceId}/shadow`,
    payload,
    {
      headers: {
        "ORG-ID": orgId
      }
    }
  );

  return ok;
};

export const useUpdateDeviceShadow = (fleetId, deviceId) => {
  const [selectedProject] = useFleetAndDevicesStore((state) => [
    state.selectedProject
  ]);
  const [user] = useAuthStore((state) => [state.user]);
  const queryClient = useQueryClient();

  const res = useMutation(
    async (data: IUpdateDeviceShadowPayload) =>
      await updateDeviceShadow(
        selectedProject.id,
        user.selectedOrg.id,
        fleetId,
        deviceId,
        data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getShadow");
      }
    }
  );

  return res;
};
