import produce from "immer";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ConfigState {
  version: string;
  setVersion: (x: string) => void;
}

const useConfigStore = create<ConfigState>()(
  persist(
    devtools(
      (set) => ({
        version: null,
        setVersion: (version) =>
          set(
            produce((state) => {
              state.version = version;
            }),
            false,
            "config/setVersion"
          )
      }),
      { name: "config", serialize: { options: true } }
    ),
    {
      name: "config", // unique name
      getStorage: () => localStorage // (optional) by default, 'localStorage' is used
    }
  )
);

export default useConfigStore;
