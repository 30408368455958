import { useAuthStore } from "../../store";
import { ZitadelConfig, createZitadelAuth } from "./zitadel-auth";

interface ISetTokenPrams {
  access_token: string;
  refresh_token: string;
  expiry: string;
}

export const ServerUrl = process.env.REACT_APP_CASDOOR_TOKEN_SERVER_URL;

const sdkConfig: ZitadelConfig = {
  authority: process.env.REACT_APP_ZITADEL_ENDPOINT,
  client_id: process.env.REACT_APP_ZITADEL_CLIENT_ID,
  project_resource_id: process.env.REACT_APP_ZITADEL_PROJECT_ID,
  scope: process.env.REACT_APP_ZITADEL_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_URL
};

export const ZitadelSDK = createZitadelAuth({
  ...sdkConfig,
  redirect_uri: process.env.REACT_APP_URL + "/callback"
});

// export const SetToken = (data: ISetTokenPrams) => {
//   const setAuthToken = useAuthStore.getState().setAuthToken;

//   setAuthToken({
//     token: data.access_token,
//     refreshToken: data.refresh_token,
//     expiry: data.expiry
//   });
// };

// export const goToLink = (link: string) => {
//   window.location.href = link;
// };

// export const getZitadelSDKWithoutRedirect = () => {
//   const config: ZitadelConfig = {
//     ...sdkConfig
//   };

//   const zitadel = createZitadelAuth(config);

//   return zitadel;
// };

// export const getZitadelSDKForRedictPath = (
//   redirectPath: string,
//   additionalConfigs: ZitadelConfig = {}
// ) => {
//   const config: ZitadelConfig = {
//     ...sdkConfig,
//     redirect_uri: process.env.REACT_APP_URL + redirectPath,
//     ...additionalConfigs
//   };

//   const zitadel = createZitadelAuth(config);

//   return zitadel;
// };
